  <!-- Carousel Start -->
<div id="carousel" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
        <li data-target="#carousel" data-slide-to="0" class="active"></li>
        <li data-target="#carousel" data-slide-to="1"></li>
        <li data-target="#carousel" data-slide-to="2"></li>
        <li data-target="#carousel" data-slide-to="3"></li>
        <li data-target="#carousel" data-slide-to="4"></li>
        <li data-target="#carousel" data-slide-to="5"></li>
    </ol>
    <div class="carousel-inner">
        <div class="carousel-item active">
            <img src="../../../../assets/Banners/carousel-2.jpg" alt="Carousel Image" />
            <div class="carousel-caption">
                <h1 class="animated fadeInLeft fontt">Discover Landmark Judgements: Supreme Court, AP High Court, and Telangana High Court</h1>
            </div>
        </div>
        <div class="carousel-item ">
            <img src="../../../../assets/Banners/Banner.jpg" alt="Carousel Image" />
            <div class="carousel-caption">
                <h1 class="animated fadeInLeft fontt">Access Legal Insights</h1>
            </div>
        </div>

        <div class="carousel-item ">
            <img src="../../../../assets/Banners/banner.webp" alt="Carousel Image" />
            <div class="carousel-caption">
                <h1 class="animated fadeInLeft fontt">Dive into Legal Precedents</h1>
            </div>
        </div>

        <div class="carousel-item">
            <img src="../../../../assets/Banners/banner1.webp" alt="Carousel Image" />
            <div class="carousel-caption">
                <h1 class="animated fadeInLeft fontt">Empower Your Legal Research</h1>
            </div>
        </div>


        <div class="carousel-item">
            <img src="../../../../assets/Banners/banner2.webp" alt="Carousel Image" />
            <div class="carousel-caption">
                <h1 class="animated fadeInLeft fontt">Stay Updated with Judicial Decisions</h1>
            </div>
        </div>
        <div class="carousel-item">
            <img src="../../../../assets/Banners/banner3.webp" alt="Carousel Image" />
            <div class="carousel-caption">
                <h1 class="animated fadeInLeft fontt">Enhance Your Legal Knowledge</h1>
            </div>
        </div>
    </div>



    <div class="container-fluid mt-3">
        <h3>Carousel Example</h3>
        <p>The following example shows how to create a basic carousel with indicators and controls.</p>
    </div>

    <a class="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carousel" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div>
<br />




<div id="cta" class="cta">
    <div class="container" data-aos="zoom-out">
        <div class="row g-5">
            <div class="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
                <h1 class="fontt" style="color:white">About Us</h1>
                <!--<h3>Alias sunt quas <em>Cupiditate</em> oluptas hic minima</h3>-->
                <p style="color:white" class="about_p">
                    At Lawyers Choice, we are passionate about providing lawyers, advocates, and judges with the tools they need to succeed in their legal research. We understand that navigating the complex world of legal research can be challenging, and that's why we have created a comprehensive platform that simplifies the process and makes it easier for our users to access relevant case law.
                </p>
                <a class="cta-btn align-self-start" routerLink="/about-us">Read More</a>
            </div>
            <div class="col-lg-4 col-md-6 order-first  d-flex align-items-center  box3">
                <div class="img">
                    <div id="red" class="div"></div>
                    <div id="green" class="div"></div>
                    <div id="MidnightBlue" class="div">
                        <img src="../../../../assets/lawyersimages/Capture.png" alt="" class="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="container-fluid ">
    <div class="container">
        <h1 class="fontt">Our Judgements</h1>
        <div class="row">
            <div class="col-lg-4 " routerLink="/Subscribe">
                <div class="container1">
                    <div class="intro"></div>
                    <div class="meta">
                        <div class="meta__inner">
                            <h3 class="h3 fontt">SC Judgements</h3>
                            <p class="p">
                                There are currently 34 judges (including the Chief Justice) who comprise the Supreme Court of India, the highest court in the country. The maximum possible strength is 34. According to the Constitution of India, the judges of the Supreme Court retire at the age of 65
                                <a routerLink="/Subscribe">read more ...</a>
                            </p>
                        </div>
                    </div>
                </div>

                <!--<div class="card_2 " routerLink="/Subscribe">
                    <img src="../../../../assets/lawyersimages/supremee.jpg" class="card_img" />

                    <h2 class="heading"><span>Supreme Court Judges</span></h2>
                    <p class="site_text">
                        There are currently 34 judges (including the Chief Justice) who comprise the Supreme Court of India, the highest court in the country. The maximum possible strength is 34. According to the Constitution of India, the judges of the Supreme Court retire at the age of 65
                    </p>

                </div>-->
            </div>
            <div class="col-lg-4" routerLink="/Subscribe">
                <div class="container1">
                    <div class="intro1"></div>

                    <div class="meta">
                        <div class="meta__inner">
                            <h3 class="h3 fontt">AP Court Judgements</h3>
                            <p class="p">
                                The Andhra State was formed after seperating from the erstwhile Madras State in the year 1954. The High Court for the Andhra State, with its principal seat at Guntur, was established in the year 1954 with Hon’ble Sri Justice Koka Subba Rao as the first Chief Justice.
                                <a routerLink="/Subscribe">read more ...</a>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-4" routerLink="/Subscribe">
                <div class="container1">
                    <div class="intro2"></div>

                    <div class="meta">
                        <div class="meta__inner">
                            <h3 class="h3 fontt">TS Court Judgements</h3>
                            <p class="p">
                                The Telangana State was formed after seperating from the erstwhile Madras State in the year 1954. The High Court for the TS State, with its principal seat at Guntur, was established in the year 1954 with Hon’ble Sri Justice Koka Subba Rao as the first Chief Justice.
                                <a routerLink="/Subscribe">read more ...</a>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="container err" style="background: linear-gradient(283deg, rgb(255 184 41) 0%, rgb(255 66 9) 100%);">
        <div class='container2'>
            <div class="container_content">
                <div class="container_content_inner">
                    <div class="title">
                        <h1 class="h11" style="font-family: 'roboto comdensed';font-style: normal;">Advance Search</h1>
                    </div>
                    <div class="par">
                        <p class="pp">
                            Welcome to our Advanced Search module, your gateway to efficiently explore
                            and discover the judgments that matter to you. With our powerful search capabilities,
                            you can find relevant judgments based on multiple criteria or single values, tailored to your specific
                            research needs.<br />
                            <!--Our comprehensive search feature allows you to input various parameters to
                            refine your search results. Whether you have specific keywords, citation details, headnotes,
                            judgment dates, document names, or judgment titles in mind, our advanced search module will
                            help you uncover the precise information you're looking for.-->
                        </p>
                    </div>
                    <!--<div class="btns">
                        <button class='btns_more'> See more </button>
                    </div>-->
                </div>
            </div>
            <div class="container_outer_img">
                <div class="img-inner">

                    <img src="../../../../assets/lawyersimages/advncc1.jpg" class="advance_img" />
                    <!--<img src="../../../../assets/lawyersimages/Icon_Career-4_TND.png" class="advance_img" />-->
                    


                </div>
            </div>
        </div>
    </div>
    <div class="overlay"></div>


    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <img src="../../../../assets/lawyersimages/animat-printer-color.gif" class="gif" />
                <div class="gif_div">
                    <h1 class="fontt">Print Judgements</h1>
                    With Lawyerschoice, you can effortlessly print, email, copy, and paste portions of judgements or entire cases.
                </div>
            </div>
            <div class="col-lg-2"></div>
            <div class="col-lg-6">
                <img src="../../../../assets/lawyersimages/print.jpg" class="print_page" />
            </div>
        </div>
    </div>

    <br />
    <div class="container">
        <h1 class="fontt">Our Pricing</h1>
        <div class="row">
            <div class="col-lg-4">
                <div id="card">
                    <div class="rect"></div>
                    <div class="section top-section">
                        <div class="white-text">
                            <img src="../../../../assets/lawyersimages/law2.png" style="color:white;text-align:center;" />
                            <!--<h2 style="color:white;text-align:center;">Free </h2>--><br />  <br />
                            <h3 style="color:white;text-align:center;"><sup>₹</sup>7<span>Days</span></h3>
                        </div>
                        <div></div>
                    </div>
                    <div class="section bottom-section">
                        <br /> <ul>
                            Our website offers a range of packages tailored to meet your specific needs, ranging from a 7-day free trial
                        </ul>
                        <button routerLink="/Subscribe" class="btn_1">SUBSCRIBE</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div id="card">
                    <div class="rect"></div>
                    <div class="section top-section">
                        <div class="white-text">
                            <img src="../../../../assets/lawyersimages/law1.png" style="color:white;text-align:center;" />
                            <br />   <br />   <h3 style="color:white;text-align:center;"><sup>₹</sup>30<span>Days</span></h3>
                        </div>
                        <div></div>
                    </div>
                    <div class="section bottom-section">
                        <br /> <ul>
                            With our extensive database of legal judgments, you can easily search for relevant documents based on judgment title, date, court name, citation, headnote, bench, and document type.
                        </ul>

                        <button routerLink="/Subscribe" class="btn_1">SUBSCRIBE</button>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div id="card">
                    <div class="rect"></div>
                    <div class="section top-section">
                        <div class="white-text">
                            <img src="../../../../assets/lawyersimages/law2.png" style="color:white;text-align:center;" />
                            <br />   <br />  <h3 style="color:white;text-align:center;"><sup>₹</sup>180<span>Days</span></h3>
                        </div>
                        <div></div>
                    </div>
                    <div class="section bottom-section">
                        <br /> <ul>
                            Our platform is primarily designed to assist Indian lawyers in understanding and analyzing legal precedents, and it also allows for easy printing of relevant documents.
                        </ul>

                        <button routerLink="/Subscribe" class="btn_1">SUBSCRIBE</button>
                    </div>
                </div>
            </div>
        </div><br /><br />
        <div class="row">
            <div class="col-lg-4">

            </div>
            <div class="col-lg-4">

            </div>
            <div class="col-lg-4">
                <button routerLink="/Subscribe" class="btn"> More Subscriptions</button>
            </div>

        </div>
    </div>



    <br />  <br />

</div>














