<div class="top-bar" style="background: rgb(255,215,135);
background:linear-gradient(283deg, rgb(255 184 41) 0%, rgb(255 66 9) 100%)">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7">
                <div class="logo maill0">
                    <p class="mail"><i class="fa fa-envelope"></i>&nbsp;info@Lawyerschoice.com</p>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="top-bar-right">
                    <div class="social">
                        <a href=""><i class="fab fa-twitter"></i></a>
                        <a href=""><i class="fab fa-facebook-f"></i></a>
                        <a href=""><i class="fab fa-linkedin-in"></i></a>
                        <a href=""><i class="fab fa-instagram"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Nav Bar Start -->
<div class="nav-bar">
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg bg-dark navbar-dark">
            <a href="#" class="navbar-brand" style="cursor:pointer">
                <img src="../../../../assets/lawyersimages/logo.png" style="width: 44px;border-radius:50px;cursor:pointer;" href="#" />
            </a>
            <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                <div class="logoo"style="cursor:pointer;"routerLink="/">
                    <img src="../../../../assets/lawyersimages/logo.jpg" class="logoo" style="cursor:pointer;"routerLink="/"/>
                </div>
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a href="" class="nav-link active">Home</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/about-us" class="nav-link">About&nbsp;Us</a>
                    </li>

                    <li class="nav-item dropdown" *ngIf="!LoginDetails">
                        <a class="nav-link dropdown-toggle" href="#" id="judgementsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Judgements
                        </a>
                        <div class="dropdown-menu" aria-labelledby="judgementsDropdown">
                            <a class="dropdown-item" routerLink="/login">Supreme Court</a>
                            <a class="dropdown-item" routerLink="/login">AP Court</a>
                            <a class="dropdown-item" routerLink="/login">TS Court</a>
                        </div>
                    </li>

                    <!--<li class="nav-item">
        <a routerLink="/login" class="nav-link">Judgements</a>

    </li>-->
                    <li class="nav-item" *ngIf="LoginDetails!=null">
                        <a routerLink="/Judges" class="nav-link">Judgements</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/Subscribe" class="nav-link">Subscribe</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/contact" class="nav-link">Contact</a>
                    </li>
                    <li class="nav-item" *ngIf="!LoginDetails">
                        <a routerLink="/Registration" class="nav-link">Registration</a>
                    </li>
                    <li class="nav-item" *ngIf="LoginDetails==null">
                        <a routerLink="/login" class="nav-link">Login</a>
                    </li>
                    <!--<li class="nav-item">
        <a routerLink="/Profile" class="nav-link">Profile</a>
    </li>-->
                    <li class="nav-item">
                        <div>
                            <a *ngIf="LoginDetails?.CustomerName" routerLink="/Profile" class="nav-link circle-avatar">
                                <h2 class="tooltiptext fontt h2">{{LoginDetails.CustomerName | slice:0:1}}</h2>
                                <span class="tooltiptext"></span>
                            </a>
                        </div>
                    </li>
                    <li class="nav-item" *ngIf="LoginDetails">
                        <a (click)="logout()" class="nav-link" style="cursor:pointer">Logout</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</div>
