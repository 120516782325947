import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var $


@Component({
  selector: 'app-judges',
  templateUrl: './judges.component.html',
  styleUrls: ['./judges.component.scss']
})
export class JudgesComponent implements OnInit {
    loginDet: any;
    arr: any[];
    dataResult: any;
    logindata: string;
    TotalSCJudgements: any;
    TotalAPJudgements: any;
    TotalTSJudgements: any;
    manohar: boolean = false;
   // PDFPath: string;
    HomeUrl: any;
   // PDFPath: SafeResourceUrl;
    searchQuery: any;
    JudgementTitle: string;
    Bench: string;
    DOC_Name: any;
    CitationNo: string;
    LawyerName: string;
    KeyWord: string;
    JudgementDate: any;
    PDFPath: any;
    selected1: any;
    manu: any;
    months: any;
    year: any;
    courtiiiiid: any;
    selectedYear: any;
    selectedmonth: any;


    constructor(private sanitizer: DomSanitizer,public generalService: GeneralService, public fb: FormBuilder, public http: HttpClient, public router: Router,
        private route: ActivatedRoute) {
        debugger
     
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            debugger
            this.HomeUrl = data.Webservice;

        });
    }

    ngOnInit(): void {
        this.GetJudgements(1);
        this.getDashboardCount();
    }

    Getpdf() {
        debugger
        this.manohar = true;
       
    }

    getDashboardCount() {
        debugger

        var URL = 'api/LawyerChoices/dashboardcount';
        this.generalService.GetData(URL).then((data: any) => {
            debugger
            if (data != null) {
                debugger
                var Details = data[0]
                // this.spinner.hide();
                this.TotalSCJudgements = Details.SCJudgements
                this.TotalAPJudgements = Details.ApJudgements
                this.TotalTSJudgements = Details.TSJudgements

            }
            else {

                this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
            }
        }, err => {

            this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
        });
    }

    Getpdf1(val) {
        debugger
        if (this.loginDet.PackageID != 0) {

            debugger
            const currentDate = new Date();
            const createdDate = new Date(this.loginDet.CreatedDate);
            const timeDifference = currentDate.getTime() - createdDate.getTime();
            const daysDifference = timeDifference / (1000 * 3600 * 24); // Convert milliseconds to days

            //if (daysDifference <= 7) {
            //    debugger
            //    this.router.navigate(['/subscribepage']); // Navigate to the subscribe page
            //}
            //else
                if (this.loginDet?.PackageID !== undefined && this.loginDet?.PackageID !== null) {
                debugger
                const url = "api/LawyerChoices/Get_judgementsPDF_basedon_judgmentid";
                const uploadFile = new FormData();
                uploadFile.append("Param1", val);

                this.generalService.PostData(url, uploadFile).then((data: any) => {
                    debugger
                    if (data && data.length > 0 && data[0].PDFPath) {
                        this.PDFPath = this.sanitizer.bypassSecurityTrustResourceUrl(this.HomeUrl + data[0].PDFPath);
                        this.manohar = true; // Set the variable to true to make the PDF visible
                    } else {
                        console.error('PDFPath is undefined or the data format is incorrect');
                    }
                }, err => {
                    console.error('Error fetching data from the API');
                });
            }
        }
        else {
            this.generalService.ShowAlert('OOPS', 'Please subscribe to view PDF', 'Warning');
            this.router.navigate(['/Subscribe']);
              

           
        }
    }


    //Getpdf1(val) {
    //    debugger
    //    var url = "api/LawyerChoices/Get_judgementsPDF_basedon_judgmentid";
    //    var UploadFile = new FormData();
    //    UploadFile.append("Param1", val);

    //    this.generalService.PostData(url, UploadFile).then((data: any) => {
    //        if (data && data.length > 0 && data[0].PDFPath) {
    //            debugger
    //            this.PDFPath = this.sanitizer.bypassSecurityTrustResourceUrl(this.HomeUrl + data[0].PDFPath);
    //                   this.manohar = true; // Set the variable to true to make the PDF visible
    //        } else {
    //            console.error('PDFPath is undefined or the data format is incorrect');
    //        }
    //    }, err => {
    //        console.error('Error fetching data from the API');
    //    });
    //}
    showSubscribePopup() {
        this.generalService.ShowAlert("Info", "Please subscribe  Package to view Pdf", "Info");

    }
    matchSearchQuery(person: any): boolean {
        if (!this.searchQuery) {
            return true; // If search query is empty, show all items
        }

        const searchQuery = this.searchQuery.toLowerCase();

        // Check if any property of the person object contains the search query
        return Object.values(person).some((value: any) =>
            value && value.toString().toLowerCase().includes(searchQuery)
        );
    }
    close() {
        $('#myModal').modal('hide');
    }
    showmodal() {

        $('#myModal').modal('show');
    }


    search() {
        debugger;

        var UploadFile = new FormData();

        if (this.JudgementTitle) {
            UploadFile.append("Param1", this.JudgementTitle);
        }
        if (this.Bench) {
            UploadFile.append("Param2", this.Bench);
        }
        if (this.DOC_Name) {
            UploadFile.append("Param3", this.DOC_Name);
        }
        if (this.CitationNo) {
            UploadFile.append("Param4", this.CitationNo);
        }
        if (this.LawyerName) {
            UploadFile.append("Param5", this.LawyerName);
        }
        if (this.KeyWord) {
            UploadFile.append("Param6", this.KeyWord);
        }
        if (this.JudgementDate) {
            UploadFile.append("Param6", this.JudgementDate);
        }
        var url = "api/LawyerChoices/AdvanceSearch_Judgements";
        this.generalService.PostData(url, UploadFile)
            .then(data => {
                debugger;
                this.dataResult = data;
                $('#myModal').modal('hide');
            })
            .catch(err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
            });
    }


    clear() {
        debugger
        this.JudgementTitle = '';
        this.Bench = '';
        this.DOC_Name = '';
        this.CitationNo = '';
        this.LawyerName = '';
        this.KeyWord = '';
        this.JudgementDate = '';

    }
    GetJudgements(value) {
        this.courtiiiiid=value
        debugger
        this.arr = []
        if (this.loginDet.Mobile && this.loginDet.Password) 
        this.arr.push({
            //UserID: this.loginDet.UserID,
            //TokenID: this.loginDet.TokenID,

        })

        var UploadFile = new FormData();
        UploadFile.append("Param1",value);
       // UploadFile.append("Flag", '4');
        var url = "api/LawyerChoices/Get_judgementsBasedOnCourtId";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.dataResult = data;
            //location.reload();         
           
        },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong,please try agian later', 'error');
            })
    }

    generatePDFShareableLink111(judgementId: number) {
        debugger
        const shareableURL = this.HomeUrl + judgementId;

        // Generate the WhatsApp shareable URL
        const whatsappURL = `whatsapp://send?text=${encodeURIComponent(shareableURL)}`;

        // Open WhatsApp if supported, otherwise fallback to a regular hyperlink
        if (navigator.userAgent.match(/WhatsApp/)) {
            // Open the WhatsApp chat with the shareable URL pre-filled
            window.location.href = whatsappURL;
        } else {
            // Fallback to a regular hyperlink
            const linkElement = document.createElement('a');
            linkElement.href = shareableURL;
            linkElement.target = '_blank';
            linkElement.rel = 'noopener noreferrer';
            linkElement.innerText = 'Share via WhatsApp';
            document.body.appendChild(linkElement);
            linkElement.click();
            document.body.removeChild(linkElement);
        }
    }


    generatePDFShareableLink(pdfPath: string) {
        debugger
        // Fetch the PDF file
        fetch(pdfPath)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch the PDF file');
                }
                return response.blob();
            })
            .then(blob => {
                // Create a Data URL from the PDF blob
                const reader = new FileReader();
                reader.onloadend = () => {
                    const pdfDataUrl = reader.result as string;

                    // Generate the WhatsApp shareable URL with the embedded PDF data
                    const whatsappURL = `whatsapp://send?text=${encodeURIComponent(pdfDataUrl)}`;

                    // Open WhatsApp if supported, otherwise fallback to a regular hyperlink
                    if (navigator.userAgent.match(/WhatsApp/)) {
                        // Open the WhatsApp chat with the embedded PDF data
                        window.location.href = whatsappURL;
                    } else {
                        // Fallback to a regular hyperlink
                        const linkElement = document.createElement('a');
                        linkElement.href = pdfPath;
                        linkElement.target = '_blank';
                        linkElement.rel = 'noopener noreferrer';
                        linkElement.innerText = 'Share via WhatsApp';
                        document.body.appendChild(linkElement);
                        linkElement.click();
                        document.body.removeChild(linkElement);
                    }
                };
                reader.onerror = (error) => {
                    console.error('Failed to read the PDF file:', error);
                    // Handle the error gracefully
                };
                reader.readAsDataURL(blob);
            })
            .catch(error => {
                console.error('Failed to fetch the PDF file:', error);
                // Handle the error gracefully
            });
    }


    changeTagColor(val) {
        debugger
        this.selected1 = val;
        //this.BookingTypeId = val.BookingTypeId;

    }

    calender(val) {
        debugger
        this.manu = 1;
        this.year = val;
        this.selectedYear = val;
    }

    clearrrr() {
        debugger
        this.manu = 5;
        this.selectedYear = null;
    }

    month(value) {
        debugger
        this.months = value;
        this.selectedmonth = value;

        var UploadFile = new FormData();
        UploadFile.append("Month", value);
        UploadFile.append("Year", this.year);
        UploadFile.append("Court", this.courtiiiiid);
        var url = "api/LawyerChoices/Get_Date_Bifurcation";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.dataResult =data

        },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something Went Worng,Please try agian later', 'error');
            })

    }


   

}
