import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { EmailValidators } from 'ngx-validators';
import { GeneralService } from '../../Services/generalservice.service';
import { CookieService } from 'ngx-cookie-service';
import { EncryptionService } from '../../Services/encryption.service';
declare var $

import Swal from 'sweetalert2';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
   encapsulation: ViewEncapsulation.None

})
export class RegistrationComponent {

   public router: Router;
    public form: FormGroup;
    public name: AbstractControl;
    public mobile: AbstractControl;
    public email: AbstractControl;
    public password: AbstractControl;
    public confirmPassword: AbstractControl;
    arr: any[];
    logindata: any;
    OTP: any;
    Mobile: any;
    randomnumber: any;

    constructor(router: Router, fb: FormBuilder, public generalService: GeneralService) {

       
        this.router = router;
        this.form = fb.group({
            name: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
            mobile: ['',],
            email: ['', Validators.compose([Validators.required, emailValidator])],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required]
        }, { validator: matchingPasswords('password', 'confirmPassword') });

        this.name = this.form.controls['name'];
        this.mobile = this.form.controls['mobile'];
        this.email = this.form.controls['email'];
        this.password = this.form.controls['password'];
        this.confirmPassword = this.form.controls['confirmPassword'];
    }

    public onSubmit(value: Object): void {

        debugger;
        if (this.mobile.value == undefined || this.mobile.value == "" || this.mobile.value == null) {
            debugger;
            alert("Please Enter Mobile Number")
            // this.openToast();
        }
        else {
            var UploadFile = new FormData();
            UploadFile.append("Mobile", this.mobile.value);
            UploadFile.append("Flag", "1");
            var url = "api/LawyerChoices/LC_Check_Mobile_Email"
            debugger;
            this.generalService.PostData(url, UploadFile).then(data => {
                debugger;
              
                    this.OTP = ""
                    this.Mobile = this.mobile.value;
                    this.SendOtpForMobile(value);
                   
                    $('#myModal3').modal('show');               
             
            }, err => {
            });
        }
       
    }



    Login(mobile, password) {
        debugger
        this.arr = []
        this.arr.push({
            //UserID: this.loginDet.UserID,
            //TokenID: this.loginDet.TokenID,
            Mobile: mobile,
            Password: password,

        })
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        var url = "api/LawyerChoices/Login";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            if (data != null) {
                debugger
                if (data != "Invalid User") {

                    this.logindata = JSON.stringify(data[0]);
                    localStorage.setItem("LoginDetails", this.logindata);
                    //this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials.', 'warning');
                    if (data[0].PackageID != 0) {
                        window.location.reload(); // Perform a complete page reload
                        this.router.navigate(['/Judges']).then(() => {
                           
                        });
                    }
                    else {
                        window.location.reload(); // Perform a complete page reload
                        this.router.navigate(['/Subscribe']).then(() => {
                           
                        });

                    }
                 
                }
                else {
                    this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials.', 'warning');
                }

            }
            else {
                this.generalService.ShowAlert("WARNING", "Please Enter Valid Credintials", "warning");
            }
        }, error => ({
            title: 'ERROR',
            text: 'Something went wrong. Please try again later.',
            type: 'error',
            confirmButtonText: 'Ok'

        })
        );

    }

    /*Check mobile there or not*/
    CheckMobile(Mobile) {
        debugger;
        if (Mobile == undefined || Mobile == "" || Mobile == null) {
            debugger;
            alert("Please Enter Mobile Number")
            // this.openToast();
        }
        else {
            var UploadFile = new FormData();
            UploadFile.append("Mobile", Mobile);
            UploadFile.append("Flag", "1");
            var url = "api/LawyerChoices/LC_Check_Mobile_Email"
            debugger;
            this.generalService.PostData(url, UploadFile).then(data => {
                debugger;
                if (data == 'EXIST') {

                    alert("Mobile Number Exist")
                }
                else {

                    
                }
            }, err => {
            });
        }

    }
/*end code*/

    /* send otp for registration */
    SendOtpForMobile(value: Object) {
        debugger
        this.randomnumber = Math.floor(1000 + Math.random() * 9000);
        var msg = "Your Customer OTP is:" + this.randomnumber + " LC ";
        var UploadFile = new FormData();
        UploadFile.append("Mobile", this.mobile.value);
        UploadFile.append("SMS", msg);
        UploadFile.append("OTP", this.randomnumber);
        var url = 'api/LawyerChoices/SendOtpToMobile';
        this.generalService.PostData(url, UploadFile).then(data => {
            if (data == "SUCCESS") {
              //  this.VerifyOtp(this.randomnumber, value)
            }
            else {

            }
        });
    }
    /*end code*/

    /*verifyotp for forgotpassword*/
    VerifyOtp(otp, value) {
        debugger
        if (this.randomnumber = otp) {
            $('#myModal3').modal('hide');

            debugger
            this.arr = []
            this.arr.push({
                //UserID: this.loginDet.UserID,
                //TokenID: this.loginDet.TokenID,
                CustomerName: this.name.value,
                Mobile: this.mobile.value,
                EmailID: this.email.value,
                Password: this.password.value,
                confirmPassword: this.confirmPassword.value

            })
            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '1');
            var url = "api/LawyerChoices/LC_Add_Update_Registartion";
            this.generalService.PostData(url, UploadFile).then(data => {
                debugger
                if (data = "Alredy mobile number is exist") {
                    this.generalService.ShowAlert("WARNING", "Mobile Number Exist", "warning");
                    this.Login(this.mobile.value, this.password.value)
                }
                else if (data != "") {
                  
                    this.Login(this.mobile.value, this.password.value)
                    this.router.navigate(['/Subscribe']).then(() => {
                        window.location.reload();
                    });
                }

            }, error => ({
                title: 'ERROR',
                text: 'Something went wrong. Please try again later.',
                type: 'error',
                confirmButtonText: 'Ok'

            })
            );
        }
        else {
            alert("Invalid OTP");
        }
    }
/*end region*/






}

export function emailValidator(control: FormControl): { [key: string]: any } {
    var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    if (control.value && !emailRegexp.test(control.value)) {
        return { invalidEmail: true };
    }
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
        let password = group.controls[passwordKey];
        let passwordConfirmation = group.controls[passwordConfirmationKey];
        if (password.value !== passwordConfirmation.value) {
            return passwordConfirmation.setErrors({ mismatchedPasswords: true })
        }
    }
}


