<div class="container-fluid banner_image breadcrumb__overlay">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="breadcrumb_heading">
                    <div class="banner_heading">Judgements</div>
                    <nav aria-label="breadcrumb crumb">
                        <ol class="breadcrumb">
                            <li class="Home"><a routerLink="/">Home >></a></li>
                            <li style="color:white;"><a>Judgements</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 form_1">
                <input type="text" [(ngModel)]="searchQuery" placeholder="Search Judgement..." class="form " />
                <div class="ads_h">
                    <span (click)="showmodal()" style=" cursor: pointer;">Advance&nbsp;Search</span>
                    <img src="../../../assets/lawyersimages/adserch.png" class="advance" (click)="showmodal()" title="Advance Search" />
                </div>
            </div>
          
           
            <div class="col-lg-3" (click)="GetJudgements(1)">
                <div class="cardn" [ngClass]="{'BackGroung':selected1==1}" (click)='changeTagColor(1)'>
                    <div class="row">
                        <div class="col-lg-2">
                            <img src="../../../assets/lawyersimages/Copy of Icon.webp" class="badges" />
                        </div>
                        <div class="col-lg-8">
                            <div class="card_space" (click)="GetJudgements(1)">Supreme Court </div>
                        </div>
                        <div class="col-lg-2 count">
                            <span style="float: right;"> {{TotalSCJudgements}}</span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-3" (click)="GetJudgements(3)">
                <div class="cardn" [ngClass]="{'BackGroung':selected1==3}" (click)='changeTagColor(3)'>
                    <div class="row">
                        <div class="col-lg-2">
                            <img src="../../../assets/lawyersimages/Copy of Icon.webp" class="badges" />
                        </div>
                        <div class="col-lg-8">
                            <div class="card_space" (click)="GetJudgements(3)">TS High Court </div>
                        </div>
                        <div class="col-lg-2 count">
                            <span style="float: right;">  {{TotalTSJudgements}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3" (click)="GetJudgements(2)">
                <div class="cardn " [ngClass]="{'BackGroung':selected1==2}" (click)='changeTagColor(2)'>

                    <div class="row ">
                        <div class="col-lg-2">
                            <img src="../../../assets/lawyersimages/Copy of Icon.webp" class="badges" />
                        </div>
                        <div class="col-lg-8">
                            <div class="card_space" (click)="GetJudgements(2)">AP High Court  </div>
                        </div>
                        <div class="col-lg-2 count">
                            <span style="float: right;"> {{TotalAPJudgements}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    <div class="row">
        <div class="col-lg-3">
        <div class="ads">
    <span (click)="showmodal()" style=" cursor: pointer;">Advance&nbsp;Search</span>
    <img src="../../../assets/lawyersimages/adserch.png" class="advance" (click)="showmodal()" title="Advance Search" />
</div>
        </div>
        <div class="col-lg-9">
            <div class="years">
                <button class="year" (click)="calender(2000)" [ngClass]="{ 'selected': selectedYear === 2000 }">2000</button>&nbsp;
                <button class="year" (click)="calender(2001)" [ngClass]="{ 'selected': selectedYear === 2001 }">2001</button>&nbsp;
                <button class="year" (click)="calender(2002)" [ngClass]="{ 'selected': selectedYear === 2002 }">2002</button>&nbsp;
                <button class="year" (click)="calender(2003)" [ngClass]="{ 'selected': selectedYear === 2003 }">2003</button>&nbsp;
                <button class="year" (click)="calender(2004)" [ngClass]="{ 'selected': selectedYear === 2004 }">2004</button>&nbsp;
                <button class="year" (click)="calender(2005)" [ngClass]="{ 'selected': selectedYear === 2005 }">2005</button>&nbsp;
                <button class="year" (click)="calender(2006)" [ngClass]="{ 'selected': selectedYear === 2006 }">2006</button>&nbsp;
                <button class="year" (click)="calender(2007)" [ngClass]="{ 'selected': selectedYear === 2007 }">2007</button>&nbsp;
                <button class="year" (click)="calender(2008)" [ngClass]="{ 'selected': selectedYear === 2008 }">2008</button>&nbsp;
                <button class="year" (click)="calender(2009)" [ngClass]="{ 'selected': selectedYear === 2009 }">2009</button>&nbsp;
                <button class="year" (click)="calender(2010)" [ngClass]="{ 'selected': selectedYear === 2010 }">2010</button>&nbsp;
                <button class="year" (click)="calender(2011)" [ngClass]="{ 'selected': selectedYear === 2011 }">2011</button>&nbsp;
                <button class="year" (click)="calender(2012)" [ngClass]="{ 'selected': selectedYear === 2012 }">2012</button>&nbsp;
                <button class="year" (click)="calender(2013)" [ngClass]="{ 'selected': selectedYear === 2013 }">2013</button>&nbsp;
                <button class="year" (click)="calender(2014)" [ngClass]="{ 'selected': selectedYear === 2014 }">2014</button>&nbsp;
                <button class="year" (click)="calender(2015)" [ngClass]="{ 'selected': selectedYear === 2015 }">2015</button>&nbsp;
                <button class="year" (click)="calender(2016)" [ngClass]="{ 'selected': selectedYear === 2016 }">2016</button>&nbsp;
                <button class="year" (click)="calender(2017)" [ngClass]="{ 'selected': selectedYear === 2017 }">2017</button>&nbsp;
                <button class="year" (click)="calender(2018)" [ngClass]="{ 'selected': selectedYear === 2018 }">2018</button>&nbsp;
                <button class="year" (click)="calender(2019)" [ngClass]="{ 'selected': selectedYear === 2019 }">2019</button>&nbsp;
                <button class="year" (click)="calender(2020)" [ngClass]="{ 'selected': selectedYear === 2020 }">2020</button>&nbsp;
                <button class="year" (click)="calender(2021)" [ngClass]="{ 'selected': selectedYear === 2021 }">2021</button>&nbsp;
                <button class="year" (click)="calender(2022)" [ngClass]="{ 'selected': selectedYear === 2022 }">2022</button>&nbsp;
                <button class="year" (click)="calender(2023)" [ngClass]="{ 'selected': selectedYear === 2023 }">2023</button>&nbsp;
                <button class="year" (click)="clearrrr()">Clear</button>&nbsp;
            </div>
            <div *ngIf="manu==1" class="months">
                <button class="year1" (click)="month(1)" [ngClass]="{ 'selected': selectedmonth === 1 }">jan</button>&nbsp;
                <button class="year1" (click)="month(2)" [ngClass]="{ 'selected': selectedmonth === 2 }">Feb</button>&nbsp;
                <button class="year1" (click)="month(3)" [ngClass]="{ 'selected': selectedmonth === 3 }">March</button>&nbsp;
                <button class="year1" (click)="month(4)" [ngClass]="{ 'selected': selectedmonth === 4 }">Apirl</button>&nbsp;
                <button class="year1" (click)="month(5)" [ngClass]="{ 'selected': selectedmonth === 5 }">May</button>&nbsp;
                <button class="year1" (click)="month(6)" [ngClass]="{ 'selected': selectedmonth === 6 }">june</button>&nbsp;
                <button class="year1" (click)="month(7)" [ngClass]="{ 'selected': selectedmonth === 7 }">july</button>&nbsp;
                <button class="year1" (click)="month(8)" [ngClass]="{ 'selected': selectedmonth === 8 }">Aug</button>&nbsp;
                <button class="year1" (click)="month(9)" [ngClass]="{ 'selected': selectedmonth === 9 }">Sept</button>&nbsp;
                <button class="year1" (click)="month(10)" [ngClass]="{ 'selected': selectedmonth === 10 }">Oct</button>&nbsp;
                <button class="year1" (click)="month(11)" [ngClass]="{ 'selected': selectedmonth === 11 }">Nov</button>&nbsp;
                <button class="year1" (click)="month(12)" [ngClass]="{ 'selected': selectedmonth === 12 }">Dec</button>&nbsp;
            </div>
        </div>
        <div class="col-lg-3">
            <div class="cards card_mobile scrollbar" id="style-1" >
                <div *ngFor="let person of dataResult" class="force-overflow" (click)="Getpdf1(person.JudgementID)">
                    <div class="card middle-pane" *ngIf="matchSearchQuery(person)">
                        <ng-container *ngIf="!LoginDetails?.PackageID" (click)="showSubscribePopup()">
                            <div class="card_i">
                                <div class="row ">
                                    <div class="col-lg-6 col-xs-6">
                                    </div>
                                    <div class="col-lg-6 col-xs-6">
                                        <div class="rca">
                                            Citation No
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-xs-6">
                                        Bench Name
                                    </div>
                                    <div class="col-lg-6 col-xs-6">
                                        <div style="float: right;">{{person.JudgementDate |date:'dd-MM-yyyy'}}</div>
                                    </div>
                                    <p></p>
                                    <div class="col-lg-2 col-xs-6">
                                        <img src="../../../assets/lawyersimages/pdflogo.png" class="pdg" />
                                    </div>
                                    <div class="col-lg-10 col-xs-6">
                                        <div>{{person.JudgementTitle}}</div>
                                    </div>
                                    <p></p>
                                    <div class="col-lg-6 col-xs-6">
                                        <div>
                                            <b> Head Note</b> : Your Note
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-xs-6">
                                        <div><b>Doctype </b>: Normal</div>
                                    </div>
                                </div>
                            </div>
                           
                        </ng-container>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-9">
  <br />
            <div class="">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="" *ngIf="manohar" style="height:100%;border: 1px solid #fb8d8d; ">
                            <iframe [src]="PDFPath" type="application/pdf" width="100%" height="500px">
                                PDF cannot be displayed
                            </iframe>
                        </div>
                    </div>
                    <div class="col-lg-10"></div>
                    <div>
                        <h2 class="heading"><span>&nbsp;</span></h2>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
<div class="modal" id="myModal" style=" top: 15px;left: 2%;">
    <div class="modal-dialog" role="document" style="top: -3%;">
        <div class="modal-content" >
            <div class="modal-header state modal-primary" style="background-color: #ef2700;    padding: 10px;">
                <h4 style="text-align:left;font-family: 'Roboto Condensed'; font-style: normal;color:white" class="modal-title" id="modal-primary-label">Advanced Search For judgements</h4>
                <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style="color:white"> X </span></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12">

                        <!--<div class="col-lg-12">
                            <h3 style="font-family:'roboto condensed';font-style:normal;font-size:16px;text-align: center;">Enter Document Details Like To Search... </h3>
                        </div>-->
                        <div class="col-lg-6">
                            <input type="text" placeholder="Search Judgement Title" class="inputbox bord" [(ngModel)]="JudgementTitle" /><br />
                            <input type="text" placeholder=" Search Bench Name" class="inputbox bord" [(ngModel)]="Bench" /><br />
                            <input type="text" placeholder=" Search DOC Type" class="inputbox bord" [(ngModel)]="DOC_Name" />
                            <input type="text" placeholder=" Search Citation No" class="inputbox bord" [(ngModel)]="CitationNo" />
                            <input type="text" placeholder=" Search Head Note" class="inputbox bord" [(ngModel)]="LawyerName" />
                            <input type="text" placeholder=" Search KeyWord" class="inputbox bord" [(ngModel)]="KeyWord" />
                            <input matInput type="date" name="TentativeStartdate" placeholder="JudgmentDate" style="width: 245px;" class="inputbox datepicker bord" [(ngModel)]="JudgementDate" />
                        </div>
                    </div>


                    <div class="col-lg-4"></div>

                </div>
                <br />
                <button class="buuton2" role="button" (click)="search()"> Apply</button> &nbsp;
                <button class="buuton2" role="button" (click)="clear()"> Clear</button>


            </div>
        </div>
    </div>
</div>
<script>
    function myFunction() {
        var input, filter, ul, li, a, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        ul = document.getElementById("myUL");
        li = ul.getElementsByTagName("li");
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("a")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    }
</script>
