import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { EmailValidators } from 'ngx-validators';
import { GeneralService } from '../Services/generalservice.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
//import { EncryptionService } from '../Services/';
declare var $

import Swal from 'sweetalert2';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    arr: any[];
    logindata: string;
    loginDet: any;
    mobile: any;
    password: any;

    passwordshow: boolean = false;
    MobileNo: any;
    OTP: any;
    Pwd: any;
    Confirmpassword: any;
    Remember: any;
    CheckBox: any;
    randomnumber: any;
    public form: FormGroup;
    Mobile: any;
    LoginDetails: any; token: any;
    HomeUrl: any;
    dataResult: any;

    togglePasswordVisibility() {
        this.passwordshow = !this.passwordshow;
    }

    constructor(private router: Router, fb: FormBuilder, public http: HttpClient,
        public generalService: GeneralService, private cookieService: CookieService,
    ) {
        //   localStorage.removeItem("LoginDetails");
        debugger
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));

    }
    close() {
        debugger
        $('#myModal2').modal('hide');
    }
    hide() {
        $('#myModal4').modal('hide');
    }


    ngOnInit(): void {

    }

    Login(mobile, password) {
        debugger
        this.arr = []
        this.arr.push({

            Mobile: mobile,
            Password: password,

        })
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        var url = "api/LawyerChoices/Login";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            if (data != null) {
                debugger
                if (data != "Invalid User") {

                    this.logindata = JSON.stringify(data[0]);
                    localStorage.setItem("LoginDetails", this.logindata);
                    //this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials.', 'warning');
                    if (data[0].PackageID != 0) {
                        window.location.reload(); // Perform a complete page reload
                        this.router.navigate(['/Judges']).then(() => {

                        });
                    }
                    else {
                        window.location.reload(); // Perform a complete page reload
                        this.router.navigate(['/Subscribe']).then(() => {

                        });

                    }

                }
                else {
                    this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials.', 'warning');
                }

            }
            else {
                this.generalService.ShowAlert("WARNING", "Please Enter Valid Credintials", "warning");
            }
        }, error => ({
            title: 'ERROR',
            text: 'Something went wrong. Please try again later.',
            type: 'error',
            confirmButtonText: 'Ok'

        })
        );

    }





    /* login  start */

    //Login(mobile, password) {
    //    debugger;

    //    var url = "api/Token/Gettoken";
    //    this.generalService.GetData(url).then((data: any) => {
    //        if (data && data.access_token) {
    //            this.token = data.access_token;
    //        }

    //        this.arr = [];
    //        this.arr.push({
    //            Mobile: mobile,
    //            Password: password,
    //        });

    //        var UploadFile = new FormData();
    //        UploadFile.append("Param", JSON.stringify(this.arr));
    //        var url = this.HomeUrl + "api/LawyerChoices/Login";
    //        var accessToken = this.token; // Use the token obtained from GetToken
    //      //  Set the Authorization header with the access token
    //        const headers = new HttpHeaders({
    //            'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
    //        });
    //        // Use HttpHeaders in the request
    //        this.http.post(url, UploadFile, { headers }).subscribe(data => {
    //            this.dataResult = data;
    //        },
    //            err => {
    //                this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
    //            });
    //    });
    //}


    //        var url = "api/LawyerChoices/Login";
    //        this.generalService.PostData(url, UploadFile).then(data => {
    //            debugger;
    //            if (data != null) {
    //                this.logindata = JSON.stringify(data[0]);
    //                localStorage.setItem("LoginDetails", this.logindata);

    //                var UploadFile = new FormData();
    //                UploadFile.append("Param", data[0].CustomerID);
    //                var tokenUrl = "api/Token/Gettoken";
    //                this.generalService.PostData(tokenUrl, UploadFile).then(data1 => {
    //                    debugger
    //                    if (data != null) {
    //                        if (data[0].PackageID !== 0) {
    //                            this.router.navigate(['/Judges']).then(() => {
    //                                window.location.reload(); // Perform a complete page reload
    //                            });
    //                        } else {
    //                            this.router.navigate(['/Subscribe']).then(() => {
    //                                window.location.reload(); // Perform a complete page reload
    //                            });
    //                        }
    //                    } else {
    //                        this.generalService.ShowAlert('WARNING', 'Bearer token not generated correctly.', 'warning');
    //                    }
    //                });
    //            }
    //        else {
    //                this.generalService.ShowAlert("WARNING", "Please Enter Valid Credentials", "warning");
    //            }
    //        }, error => ({
    //            title: 'ERROR',
    //            text: 'Something went wrong. Please try again later.',
    //            type: 'error',
    //            confirmButtonText: 'Ok'
    //        }));
    //    });
    //}


   

    /*end code*/

    Login1(mobile, password) {
        debugger
        this.arr = [];
        this.arr.push({
            //UserID: this.loginDet.UserID,
            //TokenID: this.loginDet.TokenID,
            Mobile: mobile,
            Password: password,
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        var url = "api/LawyerChoices/Login";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            if (data != null) {
                this.logindata = JSON.stringify(data[0]);
                localStorage.setItem("LoginDetails", this.logindata);

                var UploadFile = new FormData();
                UploadFile.append("Param", data[0].CustomerID);
                var url = "api/Token/Gettoken";
                this.generalService.PostData(url, UploadFile).then(data1 => {
                    // Check if the bearer token has changed
                    if (data[0].BearerToken != this.loginDet.BearerToken) {
                        // Perform logout action here, e.g., clearing local storage
                        localStorage.removeItem("LoginDetails");
                        // Redirect to the login page or perform other necessary actions
                        this.router.navigate(['/login']);
                    } else {
                        // Bearer token has not changed
                        if (data[0].PackageID != 0 && data[0].BearerToken == data[0].BearerToken) {
                            this.router.navigate(['/Judges']).then(() => {
                                window.location.reload(); // Perform a complete page reload
                            });
                        } else {
                            this.router.navigate(['/Subscribe']).then(() => {
                                window.location.reload(); // Perform a complete page reload
                            });
                        }
                    }
                });

            }
            else {
                this.generalService.ShowAlert("WARNING", "Please Enter Valid Credintials", "warning");
            }
        }, error => ({
            title: 'ERROR',
            text: 'Something went wrong. Please try again later.',
            type: 'error',
            confirmButtonText: 'Ok'
        }));
    }







    /* upadte barrer token */
    updatetoken() {
        debugger
        var UploadFile = new FormData();
        UploadFile.append('Param', this.loginDet.CustomerID);
        var url = "api/Token/Gettoken";
        this.generalService.PostData(url, UploadFile).then((data: any) => {

        }).catch(error => {
        console.error('Error updating PackageID:', error);
        });
    }


    /*forgot password*/
    ForgotPassword() {
        this.MobileNo = "";
        this.OTP = "";
        this.Pwd = "";
        this.Confirmpassword = "";
        $('#myModal2').modal('show');
    }
    /*end code*/

    /*Check mobile there or not*/
    CheckMobile(Mobile) {
        debugger;
        if (Mobile == undefined || Mobile == "" || Mobile == null) {
            debugger;
            alert("Please Enter Mobile Number")
            // this.openToast();
        }
        else {
            var UploadFile = new FormData();
            UploadFile.append("Mobile", Mobile);
            UploadFile.append("Flag", "1");
            var url = "api/LawyerChoices/LC_Check_Mobile_Email"
            debugger;
            this.generalService.PostData(url, UploadFile).then(data => {
                debugger;
                if (data == 'EXIST') {
                    this.OTP = ""
                    this.Mobile = Mobile;
                    this.SendOtpForMobile();
                    $('#myModal2').modal('hide');
                    $('#myModal3').modal('show');
                }
                else {

                    alert("Please Enter Valid Mobile Number")
                }
            }, err => {
            });
        }

    }
/*end code*/

    /* send otp for registration */
    SendOtpForMobile() {
        debugger
        this.randomnumber = Math.floor(1000 + Math.random() * 9000);
        var msg = "Your Customer OTP is:" + this.randomnumber + " LC ";
        var UploadFile = new FormData();
        UploadFile.append("Mobile", this.Mobile);
        UploadFile.append("SMS", msg);
        UploadFile.append("OTP", this.randomnumber);
        var url = 'api/LawyerChoices/SendOtpToMobile';
        this.generalService.PostData(url, UploadFile).then(data => {
            if (data == "SUCCESS") {

            }
            else {

            }
        });
    }
    /*end code*/

    /*verifyotp for forgotpassword*/
    VerifyOtp(otp) {
        debugger
        if (this.randomnumber = otp) {
            $('#myModal3').modal('hide');
            $('#myModal4').modal('show');
        }
        else {
            alert("Invalid OTP");
        }
    }
/*end region*/
    

    /*Change password*/
    ChangePassword(password, confirmpassword) {
        if (password == undefined || password == null || password == "" || confirmpassword == undefined || confirmpassword == null || confirmpassword == "") {
            alert("Please enter password");
        }
        else {
            if (password != confirmpassword) {
                alert("password and confirmpassword must be same");
            }
            else {
                var UploadFile = new FormData();
                UploadFile.append("Mobile", this.Mobile);
                UploadFile.append("Password", password);
                var url = "api/LawyerChoices/LC_Fotgot_Password"
                debugger;
                this.generalService.PostData(url, UploadFile).then(data => {
                    debugger;
                    if (data == 'SUCCESS') {
                        this.generalService.ShowAlert('SUCESS', 'Change Password Successfully.', 'success')
                        $('#myModal4').modal('hide');
                    }
                }, err => {
                    $('#myModal4').modal('hide');
                    this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
                });
            }
        }
    }
/*endregion*/


    //Rememberb password
    RememberMe(ev) {
        debugger
        if (ev.target.checked == true) {
            this.Remember = true;
            this.cookieService.set('email', this.form.value.email);
            this.cookieService.set('password', this.form.value.password);
            this.cookieService.set('RememberMe', 'Checked');
            this.CheckBox = 'Checked'
        }
        else if (ev.target.checked == false) {
            // this.cookieService.set('RememberMe', 'UnChecked');
            this.CheckBox = ''
            this.Remember = false;
            this.cookieService.deleteAll();

        }
    }


}
