
<br /><p-toast position="top-center"></p-toast>
<div class="container-fluid banner_image breadcrumb__overlay  breddd">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="breadcrumb_heading">
                    <div class="banner_heading">Subscriptions</div>
                    <nav aria-label="breadcrumb crumb">
                        <ol class="breadcrumb">
                            <li class="Home"><a routerLink="/">Home >></a></li>
                            <li style="color:white;"><a>Subscriptions</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
<br />


<div class="container">
    <div class="row">
        <div class="col-lg-4" *ngFor="let item of dataResult1">
            <div class="card1">
                <div class="card1-image">
                    <img src="../../../assets/lawyersimages/package (1).jpg" />
                </div>
                <div class="card1-text">

                    <h3 class="card1-title fontt">₹{{item.packagePrice}}&nbsp;<p class="par">/ {{item.PackageName}}</p></h3>
                    <p class="card1-body">{{item.PackageDiscription}}</p>
                </div>
                <button class="button-82-pushable" role="button" (click)="openModal(item)">
                    <span class="button-82-shadow"> </span>
                    <span class="button-82-edge"> </span>
                    <span class="button-82-front text">
                        SUBSCRIBE
                    </span>
                </button>
                <!--<div class="card1-price">SUBSCRIBE</div>-->
            </div>
        </div>

    </div>





</div>


<div class="modal" id="DeptModal" tabindex="-1" role="dialog" style="top: 15px; left: 2%;">
    <div class="modal-dialog" role="document">
        <div class="modal-content modal-content-card">
            <div class="modal-header state modal-primary" style="background-color: orangered">
                <h4 style="text-align: left; color: white;" class="modal-title fontt" id="modal-primary-label">Package Details</h4>
                <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" style="color: white">X</span>
                </button>
            </div>
            <div class="modal-body" style="background-image: url('../../../assets/lawyersimages/banner_half_trans.png');">
                <div class="card">
                    <div class="row content">
                        <div class="col-lg-12">
                            <div>
                                <h1 class="fontt"><b>Checkout</b></h1>
                            </div>
                        </div>
                        <hr />
                        <div class="col-lg-6 col-md-6">
                            <b>Package</b>
                        </div>
                        <div class="col-lg-6 col-md-6" style="text-align:center">
                            Billing
                        </div>
                        <div class="col-lg-6 col-md-6">
                            Summary
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div>Your Package Duration</div>
                                <!--<div>Your Package Price</div>-->
                                <h4 class="fontt">Lawyers Choice</h4>
                                <h5 class="fontt">Description</h5>
                              {{Pdescription}}
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12" style="background-image: url('../../../assets/lawyersimages/bg1.png');">
                                <div class="amount nt subs">{{Package_Name}}</div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <h5 class="fontt">Package Price</h5>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="amount subs">&#X20B9;{{Pack_Price}}</div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <h5 style="font-family: poppins; font-style:normal;color: black;">
                                    GST : 15%
                                </h5>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="amount subs">&#X20B9;{{GSTprice}}</div>
                            </div>
                            <hr />
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <h5 style="color: black; font-family: poppins;font-style:normal;">Total</h5>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="amount subs">&#X20B9;{{Pack_PricewithGst}}</div>
                            </div>
                            <div class="col-12 text-center">
                                <button class="btn pop" (click)="selectMood()">Proceed to Pay</button>

                                <!--<button  type="button" class="cartbtn" (click)="procedtoBuy(CartProducts, n)">Proceed to buy</button>-->

                                <!--<button *ngIf="!loginStatus" type="button" class="cartbtn" routerLink="/CustomerLogin">Proceed to buy</button>-->

                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal1">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header state modal-primary">
                <h4 style="text-align:left;" class="modal-title fontt" id="modal-primary-label">Select Payment mode</h4>



            </div>
            <div class="modal-body divvv">
                &nbsp;&nbsp; <input type="radio" name="mode" (change)="getPaymentType('HandCash')" /><span>&nbsp;&nbsp;&nbsp;Cash on delivery</span><br /><br />
                &nbsp;&nbsp;&nbsp;<input type="radio" name="mode"  (click)="makepayment('UPI_INTENT', 'RegularCheckout')"/><span>&nbsp;&nbsp;&nbsp;Online payment</span>
                <br />
                <br />
                <div class="modal-footer">

                    <button id="closemodal" type="button" class="btn1 btn-success btnnn btnnn1" (click)="PlaceOrder(Package_ID)" style="">Ok</button>
                    <button id="closemodal" type="button" class="btn1 btn-danger btnn" (click)="cancel()" style="">Cancel</button>
                </div>

            </div>
        </div>
    </div>
</div>
