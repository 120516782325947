<div class="container-fluid banner_image breadcrumb__overlay">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="breadcrumb_heading">
                    <div class="banner_heading">Login</div>
                    <nav aria-label="breadcrumb crumb">
                        <ol class="breadcrumb">
                            <li class="Home"><a routerLink="/">Home >></a></li>
                            <li style="color:white;"><a>Login</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div><br />
      <div class="container">
          <div class="container1">

              <div class="forms-container1">
                  <div class="signin-signup">
                      <form action="login.php" method="POST">
                          <h2 class="title fontt">Login Here!..</h2>
                          <div class="input-field">
                              <i class="fas fa-user"></i>
                              <input type="text" [(ngModel)]="mobile" id="mobile" name="mobile" placeholder="Mobile" required>
                          </div>
                          <div class="input-field">
                              <i class="fas fa-lock"></i>
                              <input type="{{ passwordshow ? 'text' : 'password' }}" name="contraseña" autocomplete="current-password" placeholder="Password" id="id_password" [(ngModel)]="password">
                              <span class="toggle-password" (click)="togglePasswordVisibility()">
                                  <i class="fa" [ngClass]="{ 'fa-eye': passwordshow, 'fa-eye-slash': !passwordshow }"></i>
                              </span>
                          </div>
                          <a (click)="ForgotPassword()" class="transition pull-right" style="cursor:pointer;">Forgot password?</a>
                          <input type="submit" (click)="Login(mobile,password)" class="btn solid">
                      </form>
                  </div>
              </div>
              <div class="panels-container">
                  <div class="panel left-panel">
                      <div class="content">
                          <h3 class="fontt">You don't have an account?</h3>
                          <p>Create your account right now to follow people and like publications</p>
                          <button class="btn transparent" id="sign-up-btn" routerLink="/Registration">Register</button>
                      </div>
                      <img src="img/log.svg" class="image" alt="">
                  </div>

                  <div class="panel right-panel">
                      <div class="content">
                          <h3>Already have an account?</h3>
                          <p>Login to see your notifications and post your favorite photos</p>
                          <button class="btn transparent" id="sign-in-btn">Sign in</button>
                      </div>
                      <img src="img/register.svg" class="image" alt="">
                  </div>
              </div>
          </div>
          <div class="modal" id="myModal2" style="margin-top:9rem;">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header state modal-primary">
                          <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter Mobile</h4>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">X</span></button>
                      </div>
                      <div class="modal-body">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <label>Mobile<span style="color:red">*</span></label>
                              <input [(ngModel)]="mobile" class="form-control Caps" placeholder="Enter Mobile" type="text">
                          </div>
                      </div>
                      <div class="modal-footer">
                          <div class="row">
                              <div class="col-lg-6 col-xs-6">
                                  <button type="submit" class="btn " (click)="CheckMobile(mobile)">Submit</button>
                              </div>
                              <div class="col-lg-6 col-xs-6" >
                                  <button id="closemodal" type="button" class="btn " data-dismiss="modal">Close</button>
                              </div>
                          </div>
                      
                      </div>
                  </div>
              </div>
          </div>
          <!-- #endregion -->
          <!-- #region check otp popup for forgot password-->
          <div class="modal" id="myModal3">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header state modal-primary">
                          <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter Otp</h4>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">X</span></button>
                      </div>
                      <div class="modal-body">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <label>OTP<span style="color:red">*</span></label>
                              <input [(ngModel)]="OTP" class="form-control Caps" placeholder="Enter Otp" type="text">
                          </div>
                          <div class="row" style="float:right;cursor:pointer;margin-right:10px">
                              <a class="resend" (click)="SendOtpForMobile()">Resend OTP</a>
                          </div>
                      </div>
                      <div class="modal-footer">
                          <div class="row">
                              <div class="col-lg-6 col-xs-6">
                                  <button type="submit" class="btn " (click)="VerifyOtp(OTP)">Submit</button>
                              </div>
                              <div class="col-lg-6 col-xs-6">
                                  <button id="closemodal" type="button" class="btn " data-dismiss="modal">Close</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <!-- #endregion -->
          <!-- #region update customer and vendor password-->
          <div class="modal" id="myModal4">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header state modal-primary">
                          <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Change Password</h4>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">X</span></button>
                      </div>
                      <div class="modal-body">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <label>Password<span style="color:red">*</span></label>
                              <input [(ngModel)]="Pwd" class="form-control Caps" placeholder="Enter password" type="text">
                              <br>
                              <label>Confirm Password<span style="color:red">*</span></label>
                              <input [(ngModel)]="Confirmpassword" class="form-control Caps" placeholder="Enter confirmpassword" type="text">
                          </div>
                      </div>
                      <div class="modal-footer">
                          <div class="row">
                              <div class="col-lg-6 col-xs-6">
                                  <button type="submit" class="btn " (click)="ChangePassword(Pwd,Confirmpassword)">Submit</button>
                              </div>
                              <div class="col-lg-6 col-xs-6">
                                  <button id="closemodal" type="button" class="btn " data-dismiss="modal">Close</button>
                              </div>
                          </div>
                         
                      </div>
                  </div>
              </div>
          </div>
      </div>

<style>
    .card border-0 box-shadow rounded-0 {
        width: 600px;
        padding: 100px;
    }

    .modal-dialog {
        max-width: 31% !important;
        margin: 1.75rem auto;
    }
</style>
