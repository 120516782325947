
<div class="container-fluid banner_image breadcrumb__overlay">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="breadcrumb_heading">
                    <div class="banner_heading">Registration</div>
                    <nav aria-label="breadcrumb crumb">
                        <ol class="breadcrumb">
                            <li class="Home"><a routerLink="/">Home >></a></li>
                            <li style="color:white;"><a>Registration</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="register">
        <div class="row">
            <div class="col-md-3 register-left">
                <img src="https://image.ibb.co/n7oTvU/logo_white.png" alt="" />
                <h3 class="fontt">Welcome</h3>
                <p class="transition">Lawyers Choice</p>
                <!--<input type="submit" name="" value="Login" routerLink="/login" class="reg_1" /><br />-->
            </div>
            <div class="col-md-9 register-right">
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <h3 class="register-heading fontt">Registration</h3>
                        <div class="row register-form">
                            <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
                                <div class="col-md-6">
                                    <div class="form-group">

                                        <input [formControl]="name" class="form-control validation-field"
                                               placeholder="Full Name" type="text">
                                        <small class="text-danger" *ngIf="form.get('name').touched">
                                            <span *ngIf="form.get('name').hasError('required')">Full Name is required</span>
                                            <span *ngIf="form.get('name').hasError('minlength')">Minimum of 3 characters</span>
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <input [formControl]="mobile" (click)="CheckMobile(form.value.mobile)" class="form-control validation-field"
                                               placeholder="Mobile number" type="text" maxlength="10">
                                        <small class="text-danger" *ngIf="form.get('name').touched">
                                            <span *ngIf="form.get('mobile').hasError('required')">mobile number is required</span>
                                            <span *ngIf="form.get('mobile').hasError('minlength')">please enter 10 digits mobile number</span>
                                        </small>
                                    </div>
                                    <div class="form-group">
                                        <input [formControl]="email" class="form-control validation-field" placeholder="Email" type="text">
                                        <small class="text-danger" *ngIf="form.get('email').touched">
                                            <span *ngIf="form.get('email').hasError('required')">Email is required</span>
                                            <span *ngIf="form.get('email').hasError('invalidEmail')">Invalid email address</span>
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <input [formControl]="password" class="form-control validation-field"
                                               placeholder="Password" type="password" minlength="6">
                                        <small class="text-danger" *ngIf="form.get('password').touched">
                                            <span *ngIf="form.get('password').hasError('required')">Password is required</span>
                                            <span *ngIf="form.get('password').hasError('minlength')">Password isn't long enough, minimum of 6 characters</span>
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <input [formControl]="confirmPassword" class="form-control validation-field"
                                               placeholder="Confirm Password" type="password">
                                        <small class="text-danger" *ngIf="form.get('confirmPassword').touched">
                                            <span *ngIf="form.get('confirmPassword').hasError('required')">Confirm Password is required</span>
                                            <span *ngIf="form.get('confirmPassword').hasError('mismatchedPasswords')">Passwords do not match</span>
                                        </small>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <img src="../../../assets/lawyersimages/logo-removebg-preview.png" class="logo" style="margin-left: 380px; margin-top: -380px;" />
                                    <button class="btnRegister" type="submit"
                                            value="Register">
                                        Register
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <!-- #region check otp popup for forgot password-->

            <div class="modal" id="myModal3">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header state modal-primary">
                            <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter Otp</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">X</span></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <label>Please Check your OTP<span style="color:red">*</span></label>
                                <input [(ngModel)]="OTP" class="form-control Caps" placeholder="Enter Otp" type="text">
                            </div>
                            <div class="row" style="float:right;cursor:pointer;margin-right:10px;margin-top:1%">
                                <a class="resend" (click)="SendOtpForMobile(form.value)">Resend OTP</a>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary" (click)="VerifyOtp(OTP,form.value)">Submit</button>
                            <button id="closemodal" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- #endregion -->

        </div>
    </div>
</div>
