<div class="container-fluid banner_image breadcrumb__overlay">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <!-- Add the following classes -->
                <div fxLayout="column" fxLayoutAlign="start start" class="breadcrumb_heading">
                    <div class="banner_heading">About Us</div>
                    <nav aria-label="breadcrumb crumb">
                        <ol class="breadcrumb">
                            <li class="Home"><a routerLink="/">Home >></a></li>
                            <li style="color:white;"><a>About Us</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid content_image">
    <section class="about-section">
        <div class="container">
            <div class="row">
                <!-- Add the following classes -->
                <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2"
                     fxLayout="column"
                     fxLayoutAlign="center start"
                     fxFlex="100%">
                    <div class="inner-column">
                        <div class="sec-title">
                            <h1><span class="titles fontt">About Us</span></h1>
                            <h2 class="fontt">Welcome to LawyersChoice!</h2>
                        </div>
                        <div class="text">
                            Welcome to our comprehensive legal research platform, where you can gain access to the judgments of the Indian Supreme Court, Telangana High Court, and Andhra Pradesh High Court. Our website offers a range of packages tailored to meet your specific needs, ranging from a 7-day free trial to extended subscription plans of 30 days, 180 days, and 5 years.
                        </div>
                        <div class="text">
                            With our extensive database of legal judgments, you can easily search for relevant documents based on judgment title, date, court name, citation, headnote, bench, and document type. Our platform is primarily designed to assist Indian lawyers in understanding and analyzing legal precedents, and it also allows for easy printing of relevant documents.

                        </div>
                        <div class="btn-box">
                            <a routerLink="/contact" class="theme-btn btn-style-one">Contact Us</a>
                        </div>
                    </div>
                </div>

                <!-- Image Column -->
                <!-- Add the following classes -->
                <div class="image-column col-lg-6 col-md-12 col-sm-12"
                     fxLayout="column"
                     fxLayoutAlign="center start"
                     fxFlex="100%">
                    <div class="inner-column wow fadeInLeft">
                        <figure class="image-1">
                            <a href="#" class="lightbox-image" data-fancybox="images">
                                <img src="../../../assets/lawyersimages/aboutus.JPG" alt="" />
                            </a>
                        </figure>
                    </div>
                </div>

            </div><br /><br /><br />
            <div class="sec-title">
                <!--<span class="title">Our Approach:</span>-->
                <h2 class="fontt">Key Features:</h2>
            </div>
            <div class="text">
                <h5 class="fontt">Comprehensive Database</h5>
                Gain access to a vast collection of judgments from the Indian Supreme Court, Telangana High Court, and Andhra Pradesh High Court. Our database is regularly updated to ensure you have access to the most recent judgments.
            </div>
            <div class="text">
                <h5 class="fontt">Advanced Search Functionality</h5>
                Our powerful search engine enables you to quickly find specific judgments based on various parameters such as judgment title, date, court name, citation, headnote, bench, and document type. Refine your search to pinpoint the exact information you need.
            </div>
            <div class="text">
                <h5 class="fontt">Detailed Judgment Information</h5>
                Each judgment entry provides comprehensive details, including the judgment title, date of the judgment, court name, citation for easy reference, headnote summarizing the key points, bench composition, and document type for better categorization.
            </div>
            <div class="text">
                <h5 class="fontt">User-Friendly Interface</h5>
                Our platform is designed to be intuitive and user-friendly, making it easy for lawyers and legal professionals to navigate and locate relevant judgments efficiently.
            </div>
            <div class="text">
                <h5 class="fontt">Package Options</h5>
                Choose from a variety of subscription plans that suit your requirements. Opt for our 7-day free trial to experience the platform's capabilities before committing to a longer-term plan. We also offer flexible plans for 30 days, 180 days, and even a comprehensive 5-year subscription.
            </div>
            <!-- Add similar responsive modifications to the remaining text elements -->
            <!-- ... -->
        </div>
    </section>
</div>
